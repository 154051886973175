export enum UnitTypesInBridge {
	Abutment = 1,
	MarylandAbutment = 3,
	Pontic = 4,
	Skipped = 5, // removed from bridge
	Crown = 6,
	ImplantAbutment = 8,
	ScanBody = 9,
	Inlay = 10,
	Onlay = 11,
	Veneer = 12,
	Missing = 13,
	Maryland = 14,
	ImplantBased = 15,
	EggshellBridge = 16,
	MockupBridge = 17
}
