<ng-container>
	<mat-form-field *ngIf="null | mapper : getFavoriteOptions.bind(this) as favoritesOptions"
					[attr.id]="'rx-mat-form-field-' + id" fxFlex [class.read-only]="isReadOnly" hideRequiredMarker>
		<mat-label [class.read-only-label]="isReadOnly && !control.value"
		>{{ label }}<span *ngIf="secondaryLabel">- {{secondaryLabel}}</span
		><span *ngIf="labelSeparator">{{ labelSeparator }}</span
		><span class="mat-form-field-required-marker" *ngIf="isRequired && control.enabled">&#32;*</span>
		</mat-label>
		<mat-select
			#favorites
			[id]="'rx-auto-select-' + id"
			disableOptionCentering
			[value]="control.value"
			[rxIsRequired]="isRequired"
			[formControl]="control"
			[rxIsDisabled]="isDisabled"
			(rxIsDisabledEvent)="handleDisableEvent($event)"
			[compareWith]="compareWith"
			[class.read-only]="isReadOnly"
			[panelClass]="getFavoritePanelClass()"
			name="option"
			(openedChange)="setViewMode($event)"
		>

			<div class="options-flex-container" [ngClass]="panelClass">
				<div
					[ngClass]="{
						'rx-favorite-options-panel': true,
						'rx-favorite-options-panel-5': !enableFavoriteManagement,
						'rx-favorite-options-panel-8': enableFavoriteManagement
					 }" #panel
				>


					<!-- NO FAV MODE -->

					<ng-container *ngIf="!enableFavoriteManagement">
						<mat-option	*ngFor="let option of options; let index = index"
									   [value]="option"
									   [id]="option | mapper : generateOptionId.bind(this)"
									   [class]="{index, option} | mapper : getOptionClassName.bind(this)"
						>{{ option.Name | translateOnDemand }}
						</mat-option>
					</ng-container>
					<ng-container *ngIf="enableFavoriteManagement">


						<!-- FAV VIEW MODE -->

						<ng-container *ngIf="!isInEditMode">
							<div class="favorites-panel">
								<mat-option
										*ngIf="favoritesOptions | mapper : showNoFavorites.bind(this)"
										disabled="true"
										value=""
										class="rx-auto-simple-select-option no-favorites"
										[class.view-mode]="isInEditMode">
									<rx-star-checkbox [checked]="false" [disabled]="true">
										{{ 'Favorites.NoFavorites' | translate }}
									</rx-star-checkbox>
								</mat-option>
								<mat-option
										*ngFor="let option of favoritesOptions; let index = index; trackBy:isTheSame"
										[value]="option"
										[id]="option | mapper : generateOptionId.bind(this)"
										[class]="{index, option} | mapper : getOptionClassName.bind(this)"
								>
									<rx-star-checkbox [checked]="true" [disabled]="true">
										{{ option.Name | translateOnDemand }}
									</rx-star-checkbox>
								</mat-option>
								<mat-divider></mat-divider>
							</div>
							<ng-container
									*ngFor="let option of (true | mapper : getOptions.bind(this)); let index = index; trackBy:isTheSame">
								<mat-option
										[value]="option"
										[id]="option | mapper : generateOptionId.bind(this)"
										[class]="{index, option} | mapper : getOptionClassName.bind(this)"
										[class.view-mode]="true"
										
								>{{ option.Name  | translateOnDemand }}
								</mat-option>
							</ng-container>
						</ng-container>


						<!-- FAV EDIT MODE -->

						<ng-container *ngIf="isInEditMode">
							<div class="favorites-panel">
								<mat-option
										*ngIf="!favoritesOptions.length"
										disabled="true"
										value=""
										class="rx-auto-simple-select-option no-favorites"
										[class.view-mode]="isInEditMode">{{ 'Favorites.NoFavorites' | translate }} </mat-option>
								<ng-container *ngFor="let option of favoritesOptions; let index = index; trackBy:isTheSame">
									<rx-star-checkbox [checked]="true"
													  [id]="option | mapper : generateOptionId.bind(this)"
													  (change)="changeSelection(option)">
										{{ option.Name | translateOnDemand }}
									</rx-star-checkbox>
								</ng-container>
								<mat-divider></mat-divider>
							</div>
							<ng-container
									*ngFor="let option of (false | mapper : getOptions.bind(this)); let index = index; trackBy:isTheSame">
								<rx-star-checkbox
										[id]="option | mapper : generateOptionId.bind(this)"
										(change)="changeSelection(option)">
									{{ option.Name | translateOnDemand }}
								</rx-star-checkbox>
							</ng-container>
						</ng-container>
					</ng-container>
				</div>


				<!-- NO FAV FOOTER -->

				<footer *ngIf="!enableFavoriteManagement && isCancellable">
					<button
							[id]="id + '-cancel-selection'"
							[disabled]="isCancelButtonDisabled"
							mat-raised-button
							class="gradient"
							(click)="handleCancelSelection($event)"
					>{{ 'Selector.ClearSelection' | translate }}</button>
				</footer>


				<!-- FAV FOOTER-->

				<footer class="favorite-footer" *ngIf="enableFavoriteManagement" [class]="isInEditMode ? 'edit' : 'view'">
					<ng-container *ngIf="!isInEditMode">
						<button
								class="gradient"
								mat-raised-button
								*ngIf="isCancellable"
								[disabled]="isCancelButtonDisabled"
								(click)="handleCancelSelection($event)"
								[id]="id + '-cancel-selection'"
						>{{ 'Selector.ClearSelection' | translate }}</button>
						<button class="gradient ml-auto" mat-raised-button color="primary" name='add' *ngIf="!favoritesOptions.length"
								(click)="switchMode()">{{ 'Favorites.Add' | translate }}</button>
						<button class="gradient ml-auto" mat-raised-button color="primary" name='edit' *ngIf="favoritesOptions.length"
								(click)="switchMode()">{{ 'Favorites.Edit' | translate }}</button>
					</ng-container>
					<ng-container *ngIf="isInEditMode">
						<span>{{ 'Favorites.EditingModeFooter' | translate }}</span>
						<button class="gradient ml-auto" mat-raised-button color="primary" (click)="saveFavorites()">{{ 'Favorites.Done' | translate }}</button>
					</ng-container>
				</footer>
			</div>
		</mat-select>
		<mat-error *ngIf="isRequired && control.enabled">{{ 'Validation.RequiredField' | translate }}</mat-error>
	</mat-form-field>
</ng-container>
