import { ShareToothPropsConfig } from '@shared/models/share-tooth-props-rule';
import { UnitTypesInBridge } from '@modules/teeth-diagram/models/unit-type-in-bridge.enum';

export const shareToothPropsRules: ShareToothPropsConfig = {
	alwaysCopy: [
		{
			propertyNames: ['PonticDesignID'],
			unitTypeNames: [UnitTypesInBridge.Pontic]
		}
	],
	whenDefaultsProvided: [
		{
			propertyNames: ['SpecificationId', 'MaterialID', 'ShadeBody'],
			unitTypeNames: [
				UnitTypesInBridge.Abutment,
				UnitTypesInBridge.Pontic,
				UnitTypesInBridge.Maryland,
				UnitTypesInBridge.ImplantBased,
				UnitTypesInBridge.Inlay,
				UnitTypesInBridge.Onlay,
				UnitTypesInBridge.EggshellBridge,
				UnitTypesInBridge.MockupBridge
			]
		}
	]
};
