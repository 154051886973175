export enum UnitTypes {
	Regular = 1,
	Crown = 2,
	Crown_ThreeQuarters = 3,
	ImplantAbutment = 4,
	Inlay = 5,
	Onlay = 6,
	Veneer = 7,
	Detachable = 8,
	Missing_edentulousSpace = 9,
	Missing_noSpace = 10,
	Deciduous = 11,
	ScanBody = 12,
	CerecGuide = 13,
	ImplantBased = 14,
	SupportingTooth = 15,
	ToBeRemoved = 16,
	Clasp = 17,
	ImplantPosition = 18,
	VeneerOrLaminates = 19,
	Missing = 21,
	EggshellCrown = 22,
	MockupTooth = 23
}
